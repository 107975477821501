// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basindan-haberler-js": () => import("./../../../src/pages/basindan-haberler.js" /* webpackChunkName: "component---src-pages-basindan-haberler-js" */),
  "component---src-pages-en-books-js": () => import("./../../../src/pages/en/books.js" /* webpackChunkName: "component---src-pages-en-books-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-kazim-yardimci-js": () => import("./../../../src/pages/en/kazim-yardimci.js" /* webpackChunkName: "component---src-pages-en-kazim-yardimci-js" */),
  "component---src-pages-en-the-inspirations-from-the-existence-js": () => import("./../../../src/pages/en/the-inspirations-from-the-existence.js" /* webpackChunkName: "component---src-pages-en-the-inspirations-from-the-existence-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kazim-yardimci-js": () => import("./../../../src/pages/kazim-yardimci.js" /* webpackChunkName: "component---src-pages-kazim-yardimci-js" */),
  "component---src-pages-kitaplar-js": () => import("./../../../src/pages/kitaplar.js" /* webpackChunkName: "component---src-pages-kitaplar-js" */),
  "component---src-pages-tasavvuf-sohbetleri-js": () => import("./../../../src/pages/tasavvuf-sohbetleri.js" /* webpackChunkName: "component---src-pages-tasavvuf-sohbetleri-js" */),
  "component---src-pages-varliktan-veriler-js": () => import("./../../../src/pages/varliktan-veriler.js" /* webpackChunkName: "component---src-pages-varliktan-veriler-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-book-post-js": () => import("./../../../src/templates/book-post.js" /* webpackChunkName: "component---src-templates-book-post-js" */),
  "component---src-templates-haberler-post-js": () => import("./../../../src/templates/haberler-post.js" /* webpackChunkName: "component---src-templates-haberler-post-js" */),
  "component---src-templates-kitap-js": () => import("./../../../src/templates/kitap.js" /* webpackChunkName: "component---src-templates-kitap-js" */),
  "component---src-templates-sohbet-js": () => import("./../../../src/templates/sohbet.js" /* webpackChunkName: "component---src-templates-sohbet-js" */),
  "component---src-templates-varliktan-veriler-post-en-js": () => import("./../../../src/templates/varliktan-veriler-post.en.js" /* webpackChunkName: "component---src-templates-varliktan-veriler-post-en-js" */),
  "component---src-templates-varliktan-veriler-post-js": () => import("./../../../src/templates/varliktan-veriler-post.js" /* webpackChunkName: "component---src-templates-varliktan-veriler-post-js" */)
}

