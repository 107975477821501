import './src/styles/main.scss'



export const onRouteUpdate = () => {
    function loadReq() {
        window.$('body').removeClass("primary-menu-open")
        window.startMe((window.jQuery))
    }

    if (typeof window !== 'undefined' && typeof window.document !== 'undefined') {
        window.addEventListener( 'DOMContentLoaded', loadReq(), false );
    }
}

